export const PrevMonthChallengesLB = [
    {username: 'ChanChinda', completed: 61, earned: 4003.00, prize: '$4000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Smokey1', completed: 36, earned: 2875.00, prize: '$2000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Junkoishere', completed: 21, earned: 1867.00, prize: '$1000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Reelss', completed: 21, earned: 1379.00, prize: '750', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'WeInThisBih', completed: 19, earned: 1426.00, prize: '600', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'lgd', completed: 15, earned: 1406.00, prize: '500', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Rekehh', completed: 15, earned: 768.00, prize: '400', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Fuqboy', completed: 13, earned: 995.00, prize: '300', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'ElderBTC', completed: 13, earned: 2770.00, prize: '250', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'huncho100', completed: 4, earned: 367.00, prize: '200', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'OopAStae', completed: 3, earned: 285.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'GGE', completed: 2, earned: 310.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: '999bredren', completed: 2, earned: 152.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'SuN1', completed: 2, earned: 105.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jollyrogger', completed: 2, earned: 130.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
]

export const PrevMonthWagerLB = [
    {username: '555', totalWagered: 45550904.24, prize: 7500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Pet', totalWagered: 2520146.64, prize: 5000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Hat', totalWagered: 1460660.27, prize: 3000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'dan', totalWagered: 1213447.81, prize: 1500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Fri', totalWagered: 760145.24, prize: 1000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'LTC', totalWagered: 629446.35, prize: 900, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'das', totalWagered: 619911.24, prize: 800, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Shr', totalWagered: 615459.70, prize: 700, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Cha', totalWagered: 290428.39, prize: 600, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Man', totalWagered: 235427.40, prize: 500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Mct', totalWagered: 230210.81, prize: 450, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Gra', totalWagered: 223635.69, prize: 400, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'War', totalWagered: 219794.42, prize: 350, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jdu', totalWagered: 203805.77, prize: 300, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'mtu', totalWagered: 199410.65, prize: 250, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Vol', totalWagered: 176685.75, prize: 225, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Maa', totalWagered: 174681.85, prize: 210, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Ree', totalWagered: 151584.59, prize: 190, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'mac', totalWagered: 150246.93, prize: 180, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Gra', totalWagered: 145366.36, prize: 170, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Cat', totalWagered: 142432.15, prize: 165, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'WeI', totalWagered: 124374.91, prize: 160, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Deg', totalWagered: 120387.76, prize: 155, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Tcl', totalWagered: 119514.74, prize: 150, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'jun', totalWagered: 117212.68, prize: 145, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Bru', totalWagered: 113331.13, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'SuN', totalWagered: 107819.98, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'tom', totalWagered: 106151.15, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jor', totalWagered: 79514.54, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Oop', totalWagered: 68987.43, prize: 0, image: 'https://btcs.gg/newcoin.svg'}
]